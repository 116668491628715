import React from 'react';

export const AppStateContext = React.createContext();

const isBrowser = typeof window !== 'undefined';

const localStorageItemPrefix = 'ca.enspryng.';

const getDefaultLanguage = () => {
  if (isBrowser) {
    const browserLanguage = navigator.language || navigator.userLanguage;
    if (browserLanguage.startsWith('fr-')) {
      return 'french';
    }
  }

  return 'english';
};

export class AppStateProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      highContrast: false,
      language: null,
      page: null,
      section: null,
    };
  }

  componentDidMount() {
    let language = localStorage.getItem(localStorageItemPrefix + 'lang');
    if (!language || !['english', 'french'].includes(language)) {
      language = getDefaultLanguage();
    }
    this.setState({ language: language });
    this.loadLanguage(language);

    const highContrast = true;
    this.setState({ highContrast: highContrast ? true : false });
  }

  setLanguage = language => {
    if (!language || !['english', 'french'].includes(language)) {
      language = getDefaultLanguage();
    }

    this.setState({
      language: language,
    });

    localStorage.setItem(localStorageItemPrefix + 'lang', language);
  };

  setPage = page => {
    this.setState({
      page: page,
    });
  };

  setSection = section => {
    this.setState({
      section: section,
    });
  };

  toggleContrast = () => {
    const highContrast = !this.state.highContrast;
    this.setState({
      highContrast: highContrast,
    });
    localStorage.setItem(localStorageItemPrefix + 'highContrast', highContrast);
  };

  toggleLanguage = () => {
    const language = this.state.language === 'french' ? 'english' : 'french';
    this.setState({
      language: language,
    });
    localStorage.setItem(localStorageItemPrefix + 'lang', language);
    this.loadLanguage(language);
  };

  loadLanguage = (language, retryCount = 0) => {
    if (isBrowser && window.OneTrust) {
      const langCode = language === 'french' ? 'fr-ca' : 'en';
      window.OneTrust.changeLanguage(langCode);
    } else if (retryCount < 10) {
      const loadLanguage = this.loadLanguage;
      setTimeout(function () {
        loadLanguage(language, retryCount + 1);
      }, (2 + retryCount) * 50);
    }
  };

  getValue = () => {
    return {
      ...this.state,
      setLanguage: this.setLanguage,
      setPage: this.setPage,
      setSection: this.setSection,
      toggleContrast: this.toggleContrast,
      toggleLanguage: this.toggleLanguage,
    };
  };

  render() {
    return <AppStateContext.Provider value={this.getValue()}>{this.props.children}</AppStateContext.Provider>;
  }
}

export function wrapRootElement({ element }) {
  return <AppStateProvider>{element}</AppStateProvider>;
}
